import React from "react";

import Layout from "../../components/Layout";
import LeafletMap from "../../components/leafletmap";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { Flex, Box } from "rebass";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  render() {
    return (
      <Layout>
        <div className="container">
          <section>
       
              <Box textAlign="center" p={3} width={1}>
                <Flex>
                  <Box p={1} textAlign={"end"} width={1 / 2}>
                    İbrahim TOPÇU {"   "}
                  </Box>
                  <Box p={1} textAlign={"start"} width={1 / 2}>
                    <a href="tel:+905335412355">
                      <FaPhoneAlt /> 0533 541 23 55
                    </a>
                  </Box>
                </Flex>
                <Flex>
                  <Box p={1} textAlign={"end"} width={1 / 2}>
                    Murat TOPÇU {"   "}
                  </Box>
                  <Box p={1} textAlign={"start"} width={1 / 2}>
                    <a href="tel:+905335412355">
                      <FaPhoneAlt /> 0533 721 11 32
                    </a>
                  </Box>
                </Flex>
              </Box>
              <Box textAlign="center" p={3} width={1}>
                <h3 style={{ fontWeight: "bold" }}>Adres</h3>
                <a href="https://maps.google.com/?q=york+Kadıköy/@40.9801391,29.0647604,14z/data=!3m1!4b1">
                  <a href="https://maps.apple.com/maps?q=york+Kadıköy/@40.9801391,29.0647604,14z/data=!3m1!4b1">
                    <FaMapMarkerAlt />
                    Hopa San. Sit. B.1 Blok No:83E Hopa/Artvin
                  </a>
                </a>
              </Box>{" "}
              <Box textAlign="center" p={3} width={1}>
                <h3 style={{ fontWeight: "bold" }}>Çalışma Saatleri</h3>
                09:00 - 18:00
              </Box>
          </section>
          <div style={{ marginTop: 50 }}>
            {typeof window !== "undefined" && (
              <LeafletMap
                position={[40.986238, 29.025818]}
                zoom={18}
                markerText="York Kadıköy"
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }
}
