import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "./leafletmap.css";

export default class LeafletMap extends React.Component {
   render() {
    return (
      <MapContainer center={[41.387287, 41.4498894]} zoom={13}>
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[41.387287,41.4498894]}>
    <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
    </Popup>
  </Marker>
</MapContainer>
    );
  }
}

